<template>
  <div class="page-header">
    <div class="page-header-content">
      <el-menu :router="true" @select="handleSelect" :default-active="activeIndex"
               class="el-menu-demo" mode="horizontal"
               background-color="#11203e"
               text-color="#fff"
               active-text-color="#fff">
        <el-menu-item><div class="brand"><strong>二进制</strong></div></el-menu-item>
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/news">资讯案例</el-menu-item>
        <el-menu-item index="/document">投放文档</el-menu-item>
<!--        <el-menu-item index="/metrics">案例资讯</el-menu-item>-->
<!--        <el-menu-item index="/question">帮助中心</el-menu-item>-->
<!--        <el-menu-item index="/debug">在线调试</el-menu-item>-->
<!--        <el-menu-item index="/exception">异常检测</el-menu-item>-->
        <el-menu-item index="/tools">常用工具</el-menu-item>
<!--        <el-menu-item>-->
<!--          <div class="header-search"><el-autocomplete-->
<!--              placeholder="请输入内容搜索内容"-->
<!--              @select="handleSelect"-->
<!--          ></el-autocomplete></div>-->
<!--        </el-menu-item>-->
      </el-menu>
    </div>
  </div>
</template>

<script>
let InitData = {
  activeIndex: '/'
}
export default {
  name: 'CommonHeader',
  beforeCreate() {
    InitData.activeIndex = window.location.hash.replace("#", "")
  },
  data() {
    return {
      activeIndex: InitData.activeIndex
    };
  },
  methods: {
    handleSelect(key, path) {
      console.log(this.$route.path)
      console.log(key, path);
      //this.$route.push(path)
    }
  }
}
</script>

<style scoped>
  .page-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    font-weight: bolder;
    //width: 100%;
    //min-width: 1200px;
    //height: 64px;
    background: #11203e;
    //display: flex;
    //box-shadow: 0 2px 11px rgba(0,0,0,.0275136);
  }
  .page-header-content{
    box-shadow: 0 2px 11px rgba(0,0,0,.0275136);
  }
  .el-menu-item {
    font-size: 16px;
  }
  .header-search {
    margin-left: 5rem;
  }
  .page-header-content {
    margin-left: 10rem;
  }
  .brand {
    font-size: 1.5rem;
  }
</style>
