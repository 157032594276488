<template>
  <div class="page-footer">
    Copyright © 2024  二进制  粤ICP备16084930号-1   联系QQ：442353346
  </div>

</template>

<style>
.page-footer {
  clear: both;
  text-align: center;
  margin-bottom: 1rem;
  font-size: .9rem;
}
</style>

<script>
export default {
  name: "CommonFooter"
}
</script>
