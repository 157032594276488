<template>
  <div id="app">
    <div>
      <CommonHeader/>
    </div>
    <div class="body-content">
      <!-- 路由出口 -->
      <!-- 路由匹配到的组件将渲染在这里 -->
      <router-view />
    </div>
    <div class="body-footer">
      <CommonFooter></CommonFooter>
    </div>
  </div>

</template>

<script>
import CommonHeader from './views/components/CommonHeader.vue'
import CommonFooter from './views/components/CommonFooter.vue'

export default {
  name: 'App',
  components: {
    CommonFooter,
    CommonHeader,
  }
}
</script>

<style>
#app {
  font-family:sans-serif, Avenir, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
  //margin-top: 60px;
}
.body-content {
  width: 80%;
  margin: 5rem auto 5rem auto;
  min-height: 30rem;
;
}
.body-footer {
  margin: auto;
  width: 100%;
  //padding-bottom: 1rem;
  //position: fixed;
  //bottom: 0;
}
</style>
