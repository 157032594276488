import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const constantRoutes = [
    {
        path: '/',
        name:'Index',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/search/Index.vue')
    },
    {
        path: '/document',
        name:'document',
        component: () => import('@/views/document/DocumentCenter.vue')
    },
    {
        path: '/metrics',
        name:'metrics',
        component: () => import('@/views/metrics/MetricsDefinition.vue')
    },
    {
        path: '/question',
        name:'question',
        component: () => import('@/views/question/AnswerQuestions.vue')
    } ,
    {
        path: '/debug',
        name:'debug',
        component: () => import('@/views/debug/OnlineDebug.vue')
    },
    {
        path: '/tools',
        name:'tools',
        component: () => import('@/views/tools/CommonTools.vue')
    },
    {
        path: '/exception',
        name:'exception',
        component: () => import('@/views/exception/ExceptionDetection.vue')
    },
    {
        path: '/search',
        name:'search',
        component: () => import('@/views/search/SearchResult.vue')
    },
    {
        path: '/news',
        name:'news',
        component: () => import('@/views/news/NewsList.vue')
    },
    {
        path: '/news/info',
        name:'news_info',
        component: () => import('@/views/news/NewsInfo.vue')
    }
]

const createRouter = () => new Router({
    // mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export default router
